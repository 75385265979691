import React from "react";
import Product_add from "../../Pages/SingleProduct/Product_add";
import Payment from "../../Pages/Payment/Payment";
import Gpaypayment from "../../Pages/SingleProduct/Assets/secure-transaction.svg";
import Devlimg from "../../Pages/SingleProduct/Assets/delivery-sec.svg";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { io } from "socket.io-client";

const socket = io("http://localhost:3002");

const CartItem = ({
  cartProducts,
  handleRemoveFromCart,
  handleQuantityChange,
  totalCartPrice,
  handleRemoveFromCartCombos,
  productDetailsCombo,
  handleReferralCodeSubmit,
  handleReferralCodeChange,
  error,
  referralDetails,
  referralCode,
}) => {
  return (
    <div className="conta">
      <div>
      {(cartProducts?.length > 0 || productDetailsCombo?.length > 0) ? (
          <>
            
            {cartProducts?.length > 0 &&
              cartProducts.map((cartProduct) => (
                <div className="map_function" key={cartProduct.id}>
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <div className="addecardimg text-center">
                        <img
                          src={cartProduct.data.imageUrl}
                          alt="Addedeimg"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="">
                        <h6 className="fw-bold d-flex justify-content-start ">
                          {cartProduct.data.name}
                        </h6>
                        <div className="d-flex ">
                          <i className="bi bi-currency-rupee fw-bold">
                            {cartProduct.data.price}
                          </i>{" "}
                          {"\u00a0"}
                          {"\u00a0"}
                          <span className="Success_color fw-bold fs-5">
                            OFF
                          </span>
                        </div>
                        <h6 className="fw-bold d-flex justify-content-start">
                          <span className="text-secondary">Color :</span>{" "}
                          {cartProduct.data.color}
                        </h6>
                        <h6 className="fw-bold d-flex justify-content-start">
                          <span className="text-secondary">Size :</span>{" "}
                          {cartProduct.data.sizecustomers}
                        </h6>
                        <p className="Success_color fs-6">
                          Lowest price in last 30 days
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <div style={{ marginTop: "-16px" }}>
                            <p className="text-muted fs-6">Quantity:</p>
                            <Product_add
                              initialQuantity={
                                cartProduct.data.itemCountcustomer
                              }
                              onQuantityChange={(newQuantity) =>
                                handleQuantityChange(
                                  cartProduct.id,
                                  newQuantity
                                )
                              }
                            />
                          </div>
                          <div
                            className="font_size"
                            style={{ marginTop: "10px" }}
                          >
                            <button
                              className="btn btn-link border-0 fw-bold px-3 py-1 rounded-pill"
                              onClick={() =>
                                handleRemoveFromCart(cartProduct.id)
                              }
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}


            {productDetailsCombo?.length > 0 &&
              productDetailsCombo.map((comboProduct) => (
                <div key={comboProduct.id}>
                  <div className="row">
                    <div className="col-md-2">
                      <img
                        src={comboProduct.data.productDetailsCombo.tumbnail}
                        alt="C"
                        className="img-fluid py-1"
                        style={{
                          height: "10vh",
                          width: "7vh",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                    <div className="col-md-10">
                      <div className=" d-flex align-items-center">
                        <p style={{ fontSize: "20px", marginTop: "10px" }}>
                          <i class="bi bi-currency-rupee"></i>{" "}
                          {comboProduct.data.productDetailsCombo.price}
                        </p>

                        <span className="mx-2 d-flex justify-content-center ms-auto">
                          <Product_add
                            initialQuantity={
                              comboProduct.data.itemCountcustomer
                            }
                            onQuantityChange={(newQuantity) =>
                              handleQuantityChange(
                                comboProduct.id,
                                newQuantity,
                                true
                              )
                            }
                          />
                        </span>
                        <div className="ms-auto">
                          <button
                            className="btn btn-link border-0 fw-bold rounded-pill"
                            onClick={() =>
                              handleRemoveFromCartCombos(comboProduct.id)
                            }
                            style={{ marginTop: "-14px", fontSize: "14px" }}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <p className="fs-6 text-danger">No items in the cart</p>
        )}
      </div>

      {/* refferal code  */}

      {productDetailsCombo.length > 0 && (
        <div className="col my-4">
          <div className="d-flex align-items-center">
            <form onSubmit={handleReferralCodeSubmit} className="d-flex">
              <input
                type="text"
                placeholder="Enter referral code"
                value={referralCode}
                onChange={handleReferralCodeChange}
                className="form-control"
                style={{ maxWidth: "300px" }}
              />
              <button
                className="btn btn-primary ms-2"
                type="submit"
                style={{ whiteSpace: "nowrap" }}
              >
                Apply
              </button>
            </form>
          </div>
          {referralDetails &&
            referralDetails.map((details, index) => (
              <div key={index} className="mt-3">
                <h5>Referral Details:</h5>
                <div className="d-flex flex-wrap font_size_refferal">
                  <div className="p-1">
                    <p>
                      <strong>Name:</strong> {details.firstname}{" "}
                      {details.lastname}
                    </p>
                  </div>
                  <div className="">
                    <p>
                      <strong>Email:</strong> {details.email}
                    </p>
                  </div>
                  <div className="p-1">
                    <p>
                      <strong>Phone:</strong> {details.phoneNumber}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          {error && <p className="text-danger font_size_refferal">{error}</p>}
        </div>
      )}

      <div className="col">
        <img src={Gpaypayment} alt="Gpaypayment" className="img-fluid" />
      </div>

      <div className="col">
        <h4 className="fw-bold">Order Summary :</h4>
        <div className="d-flex justify-content-between">
          <div>
            <p>Total MRP</p>
            <p>Bag Discount</p>
            <p>Coupon Discount</p>
            <p>Shipping Charge</p>
          </div>

          <div>
            <p>
              <i className="bi bi-currency-rupee"></i>
              {totalCartPrice}
            </p>
            <p>
              -<i className="bi bi-currency-rupee"></i>100
            </p>
            <p>
              <i className="bi bi-currency-rupee"></i>0.00
            </p>
            <p className="text-success fw-bold">Free</p>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="fw-bold">Total Payable </h5>
          </div>
          <div>
            <h5 className="fw-bold">
              <i className="bi bi-currency-rupee"></i>
              {totalCartPrice}
            </h5>
          </div>
        </div>
      </div>
      <div className="col my-4">
        <img src={Devlimg} alt="Devlimg" className="img-fluid" />
      </div>
      {/* Sticky Footer for Shopping Cart */}
      <div className="sticky-bottom bg-light p-3 w-100">
        <Payment
          cartProducts={cartProducts}
          productDetailsCombo={productDetailsCombo}
        />
      </div>
    </div>
  );
};

export default CartItem;
