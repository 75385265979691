import React, { useState, useEffect, useRef } from "react";
import "./LoginSignup.css";
import LoginSignupImg from "./assets/small.png";
import fotterimg1 from "./assets/corousal_icon_filled.svg";
import Google from "./assets/google.png";
import { useNavigate } from "react-router-dom";
import OtpForm from "./OtpForm";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  auth,
  provider,
  firestore,
  app,
  functions,
  messaging,
  storage,
} from "../../firebaseConfig";
import { signInWithPopup } from "firebase/auth";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
} from "firebase/firestore";

import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getMessaging, getToken, onMessage } from "firebase/messaging";
import GIf from "../Login/assets/loginkeyicon.gif";

const Login = () => {
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [error, setError] = useState("");
  const [previousPage, setPreviousPage] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [referralDetails, setReferralDetails] = useState(null);
  const navigate = useNavigate();
  const [fcm, setFcm] = useState("");

  useEffect(() => {
    const checkAuthStatus = async () => {
      const user = auth.currentUser;
      setIsLoggedIn(!!user);
    };

    checkAuthStatus();
  }, []);

  // useEffect(() => {
  //   const requestPermission = async () => {
  //     const messaging = getMessaging(app);
  //     try {
  //       const permission = await Notification.requestPermission();
  //       console.log("Permission: ", permission);
  //       if (permission === "granted") {
  //         console.log("wel");
  //         const token = await getToken(messaging, {
  //           vapidKey: "BNz4zI4UpV1hn_cUc8F56yFaM02chfcsCOy2HX8dep7fbJ5voVPKIlvHzwacqCbaampBo-PU3tnuOEiOdnfG2Gs",
  //         });
  //         console.log("FCM Token:", token);
  //         setFcm(token);
  //         // Store this token in your backend or use it to send notifications
  //       } else {
  //         console.log("Permission not granted");
  //       }
  //     } catch (error) {
  //       console.error("Error in requesting permission for notifications:", error);
  //     }
  //   };

  //   requestPermission();
  // }, []);

  const onMessageListener = () =>
    new Promise((resolve) => {
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });

  const isValidIndianPhoneNumber = (phoneNumber) => {
    const indianPhoneNumberRegex = /^\+91[0-9]{10}$/;
    return indianPhoneNumberRegex.test(phoneNumber);
  };

  provider.setCustomParameters({
    prompt: "select_account ",
  });

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(collection(firestore, "users"), user.uid);
      const docSnapshot = await getDoc(userDocRef);

      if (!docSnapshot.exists()) {
        await addGoogleUserToFirestore(user, fcm);
      }

      localStorage.setItem("email", user.email);
      navigate("/");
      window.location.reload();
    } catch (error) {
      setError("Error signing in with Google. Please try again later.");
      console.error("Error signing in with Google:", error);
    }
  };

  // details store

  const addGoogleUserToFirestore = async (user) => {
    try {
      const userDocRef = doc(firestore, "users", user.uid);
      const personalDetailsDocRef = doc(
        userDocRef,
        "personal_details",
        "details"
      );

      const docSnapshot = await getDoc(userDocRef);

      if (!docSnapshot.exists()) {
        // Generate referral code only if user does not have one
        let referralCode = "";
        const existingReferralQuery = query(
          collection(userDocRef, "personal_details"),
          where("email", "==", user.email)
        );
        const existingReferralSnapshot = await getDocs(existingReferralQuery);

        if (existingReferralSnapshot.empty) {
          referralCode = generateReferralCode();
        } else {
          // Use the existing referral code if user already has one
          referralCode = existingReferralSnapshot.docs[0].data().referralCode;
        }

        await setDoc(personalDetailsDocRef, {
          email: user.email,
          referralCode: referralCode,
          phoneNumber: user.phoneNumber || "",
        });
        console.log(
          "Google user added to Firestore (users collection):",
          user.uid
        );

        const referralDocRef = doc(firestore, "referrals", referralCode);
        const referralDetailsDocRef = doc(
          referralDocRef,
          "personal_details",
          "details"
        );

        await setDoc(referralDetailsDocRef, {
          email: user.email,
          referralCode,
          phoneNumber: user.phoneNumber || "",
          userId: user.uid,
        });
        console.log(
          "Google user details also stored in 'referrals' collection with referral code:",
          referralCode
        );
      } else {
        console.log("User already exists in Firestore:", user.uid);

        // Fetch the user's referral code and details from 'users' collection
        const detailsDocSnapshot = await getDoc(personalDetailsDocRef);
        if (detailsDocSnapshot.exists()) {
          console.log(
            "Referral Code (from users collection):",
            detailsDocSnapshot.data().referralCode
          );
          console.log(
            "User Details (from users collection):",
            detailsDocSnapshot.data()
          );
        } else {
          console.log(
            "User details document does not exist in 'personal_details'."
          );
        }

        const existingReferralCode = detailsDocSnapshot.data().referralCode;
        const referralDocRef = doc(
          firestore,
          "referrals",
          existingReferralCode
        );
        const referralDetailsDocRef = doc(
          referralDocRef,
          "personal_details",
          "details"
        );

        const referralDetailsDocSnapshot = await getDoc(referralDetailsDocRef);
        if (referralDetailsDocSnapshot.exists()) {
          console.log(
            "Referral Code (from referrals collection):",
            referralDetailsDocSnapshot.data().referralCode
          );
          console.log(
            "User Details (from referrals collection):",
            referralDetailsDocSnapshot.data()
          );
        } else {
          console.log("User not found in 'referrals' collection.");
        }
      }
    } catch (error) {
      console.error("Error adding Google user to Firestore:", error);
    }
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setPhoneNumberError("");
  };

  const handleSendOTP = async (e, phoneNumber) => {
    e.preventDefault();
  
    try {
      if (!phoneNumber || !isValidIndianPhoneNumber(phoneNumber)) {
        setPhoneNumberError("Please enter a valid Indian phone number.");
        return;
      }
  
      // Recaptcha verification (adjust as needed)
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha-container");
  
      // Sending OTP
      const confirmation = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        recaptcha
      );
      console.log("OTP sent successfully:", confirmation);
      setConfirmationResult(confirmation);
      setShowOTPForm(true);
      setPreviousPage("login");
      toast.success("OTP Sent Successfully");
    } catch (err) {
      console.error("Error sending OTP:", err);
      if (err.code === 'auth/invalid-phone-number') {
        setError("Invalid phone number.");
      } else if (err.code === 'auth/too-many-requests') {
        setError("Too many requests. Please try again later.");
      } else {
        setError("Error sending OTP. Please try again later.");
      }
      toast.error(err.message);
    }
  };
  

  // const handleSendOTP = async (e, phoneNumber) => {
  //   e.preventDefault();
  //   try {
  //     console.log("Sending OTP...");

  //     if (!phoneNumber || !isValidIndianPhoneNumber(phoneNumber)) {
  //       setPhoneNumberError("Please enter a valid Indian phone number.");
  //       return;
  //     }

  //     console.log("Initializing Recaptcha...");
  //     const recaptcha = new RecaptchaVerifier(auth, "recaptcha-container");
  //     console.log("Recaptcha initialized.");

  //     console.log("Sending OTP to phone number:", phoneNumber);
  //     const confirmation = await signInWithPhoneNumber(
  //       auth,
  //       phoneNumber,
  //       recaptcha
  //     );
  //     console.log("OTP sent successfully:", confirmation);
  //     setConfirmationResult(confirmation);
  //     setShowOTPForm(true);
  //     setPreviousPage("login");
  //     toast.success("OTP Sent Successfully");
  //   } catch (err) {
  //     console.error("Error sending OTP:", err);
  //     setError(err.message);
  //     toast.error("Error sending OTP. Please try again later.");
  //   }
  // };

  const handleVerifyOTP = async (otp) => {
    try {
      const userCredential = await confirmationResult.confirm(otp);
      const phoneNumber = userCredential.user.phoneNumber;
      const userId = userCredential.user.uid;
      toast.success("OTP Verified Successfully...");

      const userDocRef = doc(collection(firestore, "users"), userId);
      const docSnapshot = await getDoc(userDocRef);
      if (!docSnapshot.exists()) {
        await addPhoneNumberToFirestore(userId, phoneNumber);
      }

      navigate("/");
      window.location.reload();
    } catch (err) {
      setError("Invalid OTP. Please try again.");
    }
  };

  const phoneNumberRef = useRef(null);

  const handleResendOTP = async () => {
    try {
      console.log("Resending OTP...", confirmationResult);
      const phoneNumber = phoneNumberRef.current.value;
      console.log(phoneNumber, "phoneNumberphoneNumber");
      if (!confirmationResult) {
        setError("No confirmation result available. Please try again.");
        return;
      }

      const recaptcha = new RecaptchaVerifier(auth, "recaptcha-container");
      await signInWithPhoneNumber(auth, phoneNumber, recaptcha);

      toast.success("OTP Resent Successfully");
    } catch (err) {
      console.error("Error resending OTP:", err);
      setError("Error resending OTP. Please try again later.");
      toast.error("Error resending OTP. Please try again later.");
    }
  };

  const addPhoneNumberToFirestore = async (userId, phoneNumber) => {
    try {
      const userDocRef = doc(firestore, "users", userId);
      const personalDetailsDocRef = doc(
        userDocRef,
        "personal_details",
        "details"
      );

      const docSnapshot = await getDoc(personalDetailsDocRef);

      if (!docSnapshot.exists()) {
        const referralCode = generateReferralCode();

        // Add phone number, referral code, and fcmToken to user's personal details
        await setDoc(personalDetailsDocRef, {
          phoneNumber,
          referralCode,
          // fcmToken,
        });
        console.log("Phone number added to user's personal details.");

        const referralDocRef = doc(firestore, "referrals", referralCode);
        const referralDetailsDocRef = doc(
          referralDocRef,
          "personal_details",
          "details"
        );

        await setDoc(referralDetailsDocRef, {
          phoneNumber,
          referralCode,
          userId,
        });

        console.log(
          "Details stored in 'referrals/personal_details/details' document with referral code as ID."
        );
      } else {
        console.log("Phone number already exists in user's personal details.");
      }
    } catch (err) {
      console.error("Error adding phone number:", err);
    }
  };

  const generateReferralCode = () => {
    return Math.random().toString(36).substring(2, 10).toUpperCase();
  };

  const handleGoBack = () => {
    if (previousPage === "login") {
      setShowOTPForm(false);
      setError("");
    }
  };

  const logout = async () => {
    try {
      localStorage.clear();
      await auth.signOut();
      toast.success("Successfully logged out");
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row my-1">
          <div className="col-lg-7 col-md-12 col-12">
            <div className="login_image_logo text-center">
              <img src={LoginSignupImg} alt="" className="img-fluid" />
            </div>
            <div className="my-3">
              <p className="text-white fw-bold fs-5 text-center family_font">
                Join the TUNi Family for a delightful shopping experience
              </p>
            </div>
            <div className="row my-5">
              <div className="col-lg-4 col-md-6 col-sm-4 d-none d-md-block mb-3 d-flex">
                <div className="shadow_lg d-flex flex-column w-100">
                  <div className="text-center small_image">
                    <img src={fotterimg1} alt="" className="img-fluid my-3" />
                  </div>
                  <div className="py-2 flex-grow-1">
                    <p className="text-center heading_font text-white fw-bold">
                      Premium Quality Clothes
                    </p>
                    <p className="text-center text-white para_font">
                      Quality, comfort, and style – TUNi delivers on all fronts
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-4 d-none d-md-block mb-3 d-flex">
                <div className="shadow_lg d-flex flex-column w-100">
                  <div className="text-center small_image">
                    <img src={fotterimg1} alt="" className="img-fluid my-3" />
                  </div>
                  <div className="py-2 flex-grow-1">
                    <p className="text-center heading_font text-white fw-bold">
                      Great Customer Experience
                    </p>
                    <p className="text-center text-white para_font">
                      For us customer experience comes first -TUNi
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-4 d-none d-md-block mb-3 d-flex">
                <div className="shadow_lg d-flex flex-column w-100">
                  <div className="text-center small_image">
                    <img src={fotterimg1} alt="" className="img-fluid my-3" />
                  </div>
                  <div className="py-2 flex-grow-1">
                    <p className="text-center heading_font text-white fw-bold">
                      Best Team Behind Your Clothes
                    </p>
                    <p className="text-center text-white para_font">
                      We have the best talents to produce clothes you love
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* otp */}
          <div
            className="col-lg-5 col-md-12 col-12"
            style={{ background: "white", borderRadius: "10px" }}
          >
            <div className="my-1">
              {isLoggedIn ? (
                <p className="text-danger text-center mt-3">
                  Error: Already logged in. Please log out to access this page.
                  <button
                    className="px-1 border-0 py-1 bg-info"
                    onClick={logout}
                  >
                    Logout
                  </button>
                </p>
              ) : (
                <>
                  {showOTPForm ? (
                    <OtpForm
                      handleVerifyOTP={handleVerifyOTP}
                      handleResendOTP={handleResendOTP}
                      phoneNumber={phoneNumber}
                      handleGoBack={handleGoBack}
                      setError={setError}
                      error={error}
                    />
                  ) : (
                    <>
                      <h4 className="text-center fw-bold my-3 py-1">
                        Delighted to have you!
                      </h4>
                      <p className="text-center text-secondary py-1">
                        Enter your number or Email to Login/Signup.
                      </p>
                      <div className="text-center">
                        <form>
                          <div className="custom-border my-1">
                            <PhoneInput
                              country={"us"}
                              placeholder="Enter phone number"
                              value={phoneNumber}
                              ref={phoneNumberRef}
                              onChange={handlePhoneNumberChange}
                            />
                          </div>
                          {phoneNumberError && (
                            <p className="text-danger">{phoneNumberError}</p>
                          )}
 {/* --------------------------------------------------------------------------------------------------- */}
<div id="recaptcha-container"></div>
                          <div>
                            <button
                              onClick={(e) => handleSendOTP(e, phoneNumber)}
                              className="my-4 py-1 border-0 custom-border1 px-2"
                            >
                              Login with OTP{" "}
                              <i className="bi bi-arrow-right fs-6 fw-bold"></i>
                            </button>
                          </div>
                        </form>
                        <div className="text-center my-2 py-1">
                          <span className="text-muted">─────────</span>{" "}
                          <span className="mx-2">or</span>{" "}
                          <span className="text-muted">─────────</span>
                        </div>
                        <div className="img_google">
                          <button
                            className="px-5 py-2"
                            onClick={handleGoogleSignIn}
                          >
                            <img
                              src={Google}
                              alt="google"
                              className="img-fluid px-2"
                            />
                            Google
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div className="row">
              <div className="col text-center">
                <p className="my-5 text-secondary">
                  By logging in, you're agreeing to our?{" "}
                  <a href="/PrivacyPolicy" className="text-secondary">
                    Privacy Policy.
                  </a>
                  <a href="/TermConditions" className="text-secondary">
                    Terms of Service
                  </a>
                </p>
              </div>
            </div>
          </div>
          {/* end otp  */}
        </div>
      </div>
    </>
  );
};

export default Login;
