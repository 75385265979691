import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebaseConfig"; // Ensure this points to your Firebase configuration
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Chatbot.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const Chatbot = () => {
  const [currentQuestion, setCurrentQuestion] = useState(
    "Are you looking for Men or Women clothing?"
  );
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  };

  const categories = ["Tshirt", "Shirt", "Pant"];
  const subcategories = {
    Tshirt: ["full sleve", "half sleve", "collar", "round neck", "v-neck"],
    Shirt: ["Half sleeve", "Full sleeve"],
    Pant: ["Jeans", "Chinos", "Shorts"],
  };
  const styles = ["Plain", "Printed", "check"];

  const fetchProductDetails = async (gender, category, subcategory, style) => {
    try {
      const subcollectionPath = `clothes/${gender}/${category}/${subcategory}/${style}`;
      const subcollectionRef = collection(firestore, subcollectionPath);
      console.log("path", subcollectionPath);

      const querySnapshot = await getDocs(subcollectionRef);
      const products = [];
      querySnapshot.forEach((doc) => {
        products.push({ id: doc.id, ...doc.data() });
      });

      console.log("Fetched products:", products);
      setProductDetails(products);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  useEffect(() => {
    if (
      selectedGender &&
      selectedCategory &&
      selectedSubcategory &&
      selectedStyle
    ) {
      fetchProductDetails(
        selectedGender,
        selectedCategory,
        selectedSubcategory,
        selectedStyle
      );
    }
  }, [selectedGender, selectedCategory, selectedSubcategory, selectedStyle]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleSend = () => {
    // Handle the send action, e.g., submit search query or perform an action
    console.log("Search query submitted:", searchQuery);
  };

  const filteredProducts = productDetails.filter((product) =>
    product.name.toLowerCase().includes(searchQuery)
  );

  return (
    <div className="chatbot">
      <div className="chatbot-header">GP-TUNi</div>
      <div className="chatbot-body">
        <p>{currentQuestion}</p>
        {!selectedGender &&
          ["Men", "Women", "Kids"].map((gender) => (
            <button
              key={gender}
              onClick={() => {
                setSelectedGender(gender);
                setCurrentQuestion(
                  `What category are you looking for in ${gender} clothing?`
                );
              }}
            >
              {gender}
            </button>
          ))}
        {selectedGender &&
          !selectedCategory &&
          categories.map((category) => (
            <button
              key={category}
              onClick={() => {
                setSelectedCategory(category);
                setCurrentQuestion(`Select a subcategory for ${category}`);
              }}
            >
              {category}
            </button>
          ))}
        {selectedCategory &&
          !selectedSubcategory &&
          subcategories[selectedCategory].map((subcategory) => (
            <button
              key={subcategory}
              onClick={() => {
                setSelectedSubcategory(subcategory);
                setCurrentQuestion(`Select a style for ${subcategory}`);
              }}
            >
              {subcategory}
            </button>
          ))}
        {selectedSubcategory &&
          !selectedStyle &&
          styles.map((style) => (
            <button
              key={style}
              onClick={() => {
                setSelectedStyle(style);
                setCurrentQuestion(
                  `Here are the products for ${selectedSubcategory} (${style})`
                );
              }}
            >
              {style}
            </button>
          ))}
        {filteredProducts.length > 0 && (
          <Slider {...sliderSettings} className="product-details-slider">
            {filteredProducts.map((product, index) => (
              <div key={index} className="product-detail">
                <Link
                  to={`/SingleProducts/${product.id}`}
                  className="text-decoration-none border-0"
                >
                  <img src={product.imageUrl[0]} alt={product.name} />
                  <p>{product.name}</p>
                  <p>Price: {product.price}</p>
                </Link>
              </div>
            ))}
          </Slider>
        )}
        <div className="product-list-button">
          <div className="divider-text">
            <span>-----------</span> or <span>-----------</span>
          </div>
          <div>
            <p className="customerc">Customer Choice The TUNi Store.?</p>
          </div>
        </div>
      </div>

      <div className="chatbot-footer">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={handleSearch}
          />
          <button className="send-icon" onClick={handleSend}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
