import React, { useState, useEffect, useRef } from "react";
import "./ReferAndWallet.css";
import img02 from "./Assets/Images/img02.png";
import img01 from "./Assets/Images/img01.png";
import img03 from "./Assets/Images/img3.png";
import img04 from "./Assets/Images/img4.png";
import img05 from "./Assets/Images/img5.png";
import img06 from "./Assets/Images/img6.png";
import Header from "../../Compoment/Header/Header";
import Footer from "../../Compoment/Footer/Footer";

import { firestore, auth } from "../../firebaseConfig";
import { doc, collection, getDocs, updateDoc } from "firebase/firestore";
import { Modal } from "bootstrap";

const ReferAndWallet = () => {
  const [orderProducts, setOrderProducts] = useState([]);
  const [orderProductsCombos, setOrderProductsCombos] = useState([]);
  const [profileDetails, setProfileDetails] = useState([]);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [referralDetails, setReferralDetails] = useState(null);
  const [earningss, setEarningss] = useState(0);
  const [earnings, setEarnings] = useState(0);
  const [openWidthdraw, setOpenWidthDraw] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [upiId, setUpiId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [upiIdError, setUpiIdError] = useState("");
  const modalRef = useRef(null); // Reference to the modal

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        fetchOrderItems(user.uid);
        fetchOrderItemsCombos(user.uid);
        fetchProfileDetails(user.uid);
      } else {
        setUserId(null);
        setOrderProducts([]);
        setOrderProductsCombos([]);
        setReferralDetails(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchOrderItems = async (userId) => {
    try {
      const userDocItem = collection(
        firestore,
        "AllOrderList",
        userId,
        "OrderAddress_History"
      );
      const userDocSnap = await getDocs(userDocItem);
      const orderProducts = [];
      userDocSnap.forEach((doc) => {
        orderProducts.push({ id: doc.id, data: doc.data() });
      });
      setOrderProducts(orderProducts);
      setLoading(false); // Set loading state to false when order products are fetched
    } catch (error) {
      console.error("Error fetching order products:", error);
    }
  };

  const fetchOrderItemsCombos = async (userId) => {
    try {
      const userDocItem = collection(
        firestore,
        "AllOrderList",
        userId,
        "OrderAddress_History_Combos"
      );
      const userDocSnap = await getDocs(userDocItem);
      const orderProductsCombos = [];
      userDocSnap.forEach((doc) => {
        orderProductsCombos.push({ id: doc.id, data: doc.data() });
      });
      setOrderProductsCombos(orderProductsCombos);
      setLoading(false); // Set loading state to false when order products are fetched
    } catch (error) {
      console.error("Error fetching combo order products:", error);
    }
  };

  const fetchProfileDetails = async (userId) => {
    try {
      const userDocRef = doc(firestore, "users", userId);
      const personalDetailsRef = collection(userDocRef, "personal_details");
      const querySnapshot = await getDocs(personalDetailsRef);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        setProfileDetails(userData);
        console.log(userData, "setProfileDetails CODE ...");
      }
    } catch (err) {
      console.error("Error fetching profile:", err);
    }
  };

  useEffect(() => {
    if (userId && profileDetails.referralCode) {
      fetchReferralDetails(profileDetails.referralCode);
    }
  }, [userId, profileDetails.referralCode]);

  const fetchReferralDetails = async (referralCode) => {
    try {
      const referralDocRef = doc(firestore, "referrals", referralCode);
      const referralDetailsRef = collection(
        referralDocRef,
        "Use_Referal_Count"
      );
      const querySnapshot = await getDocs(referralDetailsRef);

      if (!querySnapshot.empty) {
        const referralData = querySnapshot.docs.map((doc) => doc.data());
        setReferralDetails(referralData);
        console.log(referralData, "Referral details fetched");

        const referralCount = referralData.reduce(
          (acc, curr) => acc + curr.count,
          0
        );
        const earningsIncrement = referralCount * 250;
        setEarningss(earningsIncrement);
      }
    } catch (err) {
      console.error("Error fetching referral details:", err);
    }
  };

  const handleWithdrawButtonClick = () => {
    setOpenWidthDraw((prevShow) => !prevShow);
  };

  const validateUpiId = (upiId) => {
    const upiPattern = /^[\w.-]+@[\w.-]+$/;
    return upiPattern.test(upiId);
  };

  const handleWithdrawSubmit = async (e) => {
    e.preventDefault();
    const amount = parseFloat(withdrawAmount);

    // Check if amount is valid and meets minimum requirement
    if (isNaN(amount) || amount <= 0 || amount < 100) {
      console.warn(
        "Invalid withdraw amount. Minimum withdrawal amount is 100 rupees."
      );
      return;
    }

    if (!validateUpiId(upiId)) {
      setUpiIdError(
        "Invalid UPI ID format. It should be like 'username@bank'."
      );
      return;
    }

    const detail = referralDetails.find(
      (detail) => detail.rewardAmount >= amount
    );

    if (!detail) {
      console.warn("Withdraw amount exceeds available rewards");
      return;
    }

    const newRewardAmount = detail.rewardAmount - amount;
    const referralCode = detail.referralCode;

    try {
      const referralDocRef = doc(firestore, "referrals", referralCode);
      const rewardCollectionRef = collection(
        referralDocRef,
        "Use_Referal_Count"
      );
      const rewardQuerySnapshot = await getDocs(rewardCollectionRef);

      let rewardDocRef = null;
      rewardQuerySnapshot.forEach((doc) => {
        rewardDocRef = doc.ref;
      });

      if (rewardDocRef) {
        await updateDoc(rewardDocRef, {
          rewardAmount: newRewardAmount,
        });
      } else {
        console.warn("No matching document found");
        return;
      }

      setReferralDetails((prevDetails) =>
        prevDetails.map((d) =>
          d.referralCode === detail.referralCode
            ? { ...d, rewardAmount: newRewardAmount }
            : d
        )
      );

      const modal = Modal.getInstance(modalRef.current);
      if (modal) {
        modal.hide();
      } else {
        console.error("Modal instance not found or modalRef.current is null.");
      }

      setSuccessMessage(
        "Withdrawal request successful! The amount will be credited to your bank within 4 hours."
      );
      setWithdrawAmount("");
      setUpiId("");
      setUpiIdError("");
      setEarnings((prevEarnings) => prevEarnings - amount);
    } catch (error) {
      console.error("Error updating reward amount: ", error);
    }
  };

  const shareReferral = (platform) => {
    const referralCode = profileDetails.referralCode;
    const message = `Use my below referral code in your Combo products Shopping with TUNi: ${referralCode}. Download our app for a better experience:`;
    const referralUrl = `https://tuni.club/?referral=${referralCode}`;
    const playStoreUrl = "https://play.google.com/store/apps/details?id=com.techtuni.tuni";
    const appStoreUrl = "https://apps.apple.com/in/app/tuni/id6499340367";
  
    let shareUrl = "";
  
    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          referralUrl
        )}&quote=${encodeURIComponent(
          message +'\n\n' + referralUrl + '\n\n' + playStoreUrl + '\n\n' + appStoreUrl
        )}`;
        break;
  
      case "whatsapp":
        const message1 = `Use my below referral code in your Combo products Shopping with TUNi: ${referralCode}`;
        const message2 = `Web Store: ${referralUrl}`;
        const message3 = `Download our app from Play Store: ${playStoreUrl}`;
        const message4 = `Download our app from App Store: ${appStoreUrl}`;
        shareUrl = `https://wa.me/?text=${encodeURIComponent(
          message1 +
          '\n\n' +
          message2 +
          '\n\n' +
          message3 +
          '\n\n' +
          message4
        )}`;
        break;
  
      case "instagram":
        alert('Instagram does not support direct link sharing via URL. Please share the information manually in your bio or stories.');
        return;
  
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          message + '\n\n' + referralUrl + '\n\n' + playStoreUrl + '\n\n' + appStoreUrl
        )}`;
        break;
  
      default:
        return;
    }
  
    window.open(shareUrl, "_blank");
  };
  
  return (
    <>
      <Header />
      <div className="container mt-5 my-1">
        <div className="backgroundimg">
          <div className="row">
            <p>
              {" "}
              {successMessage && (
                <div className="alert alert-success mt-3">{successMessage}</div>
              )}
            </p>
            <div className="col-lg-5 d-flex my-3">
              <div className="boxbored d-flex">
                <div className="my-4 px-2 py-1">
                  <div className="d-flex">
                    <h6>Current Balance :- </h6>
                  </div>
                  {Array.isArray(referralDetails) &&
                  referralDetails.length > 0 ? (
                    <div>
                      {referralDetails.map((detail, index) => (
                        <div className="rupee" key={index}>
                          <i className="bi bi-currency-rupee"></i>{" "}
                          {detail.rewardAmount}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No referral details found.</p>
                  )}

                  <p className="small">
                    To withdraw from your wallet, enter your full name and UPI
                    ID, then click "Verify" to ensure they match. After
                    verification, click "Submit" to complete the withdrawal,
                    ensuring all fields are correctly filled to avoid errors.{" "}
                  </p>
                </div>
                <div className="img_02 ">
                  <img src={img02} alt="" />
                  <div className="button_withdraw">
                    <button
                      type="button"
                      className="btn btn-dark d-flex align-items-center"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      onClick={handleWithdrawButtonClick}
                    >
                      Withdraw
                    </button>
                  </div>
                  <div
                    ref={modalRef}
                    className="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="false"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdropLabel">
                            Withdraw Earnings
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div>
                            <form onSubmit={handleWithdrawSubmit}>
                              <div>
                                <label>Amount:</label>
                                <input
                                  type="text"
                                  value={withdrawAmount}
                                  onChange={(e) =>
                                    setWithdrawAmount(e.target.value)
                                  }
                                  placeholder="Enter amount"
                                  required
                                />
                                <p className="text-muted oblique">
                                  You can withdraw a minimum amount of 100
                                  rupees<span className="text-danger">*</span>
                                </p>
                              </div>
                              <div>
                                <label>UPI ID :</label>
                                <input
                                  type="text"
                                  value={upiId}
                                  onChange={(e) => {
                                    setUpiId(e.target.value);
                                    setUpiIdError("");
                                  }}
                                  placeholder="Enter UPI ID"
                                  className="mx-1"
                                  required
                                />
                                <button
                                  type="submit"
                                  className="border-0 back_color_withdrew"
                                >
                                  Withdraw
                                </button>
                                {upiIdError && (
                                  <p style={{ color: "red" }}>{upiIdError}</p>
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 my-3 ">
              <div className="backbox2 d-flex">
                <div className="my-3">
                  <h4 className="my-3">Referral Count</h4>

                  {Array.isArray(referralDetails) &&
                  referralDetails.length > 0 ? (
                    <ul>
                      {referralDetails.map((detail, index) => (
                        <li key={index} className="list-group-item">
                          <h1 className="count_name my-3">{detail.count}</h1>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No referral details found.</p>
                  )}

                  <p className="small">
                    Your referral count shows how many users signed up with your
                    unique link. Each successful referral earns you rewards and
                    benefits.
                  </p>
                  <div className="d-flex  ms-auto">
                    <p className="totalearning mb-0 me-2">Total Earnings:</p>
                    <h6 className="font_size_total mb-0">
                      {earningss.toFixed(1)}
                    </h6>
                  </div>
                </div>

                <div>
                  <div className="imgo1">
                    <img src={img01} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 my-3">
              <div className="backbox3 mx-1">
                <h6>
                  <b>Spread the world and earn reward</b>
                </h6>
                <p>
                  Get 10% cashpack for each person you refer to Tuni only for
                  combo product
                </p>
                <div className="my-3">
                  <p>Share your unique referral link</p>
                  <div>
                    <div className="input-group form_control">
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Recipient's username"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          value={profileDetails.referralCode}
                        />
                      </div>

                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-addon2"
                        onClick={() =>
                          navigator.clipboard.writeText(
                            profileDetails.referralCode
                          )
                        }
                        style={{ zIndex: 1000 }}
                      >
                        <i className="bi bi-copy"></i> Copy
                      </button>
                    </div>
                  </div>

                  <div className="social_media my-3">
                    <i
                      className="bi bi-facebook"
                      onClick={() => shareReferral("facebook")}
                    ></i>
                    <i
                      className="bi bi-whatsapp"
                      onClick={() => shareReferral("whatsapp")}
                    ></i>
                    <i
                      className="bi bi-instagram"
                      onClick={() => shareReferral("instagram")}
                    ></i>
                    <i
                      class="bi bi-twitter-x"
                      onClick={() => shareReferral("twitter")}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="backbox4 mx-1">
                <ul className="ul_font">
                  <i className="bi bi-share-fill">
                    <b>Share your referral link</b>
                  </i>

                  <li className="li_font text-muted">
                    Invite your friends to join the tunii using your unique
                    referral link
                  </li>
                </ul>
                <ul className="ul_font">
                  <i className="bi bi-person-fill-add">
                    <b>Your friend joins</b>
                  </i>
                  <li className="li_font text-muted">
                    When your friends joins tuni through your shared link, they
                    become a part of our community.
                  </li>
                </ul>
                <ul className="ul_font">
                  <i className="bi bi-brightness-high-fill">
                    <b>You both earn reward</b>
                  </i>
                  <li className="li_font text-muted">
                    As a token of appreciation, both you and your friend will
                    receive 10 credits each
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row my-1">
            <div className=" col-lg-12 align-items-center">
              <h2>How it works</h2>
            </div>
            <div className=" row d-flex content_items">
              <div className="col-lg-3">
                <div className="text_box1">
                  <h4>01</h4>
                  <hr />
                  <h4>
                    REFERRAL
                    <br />
                    PROGRAM
                  </h4>
                  <div className="image-container">
                    <img src={img03} alt="" />
                  </div>
                  <p>Share your referral code with friends</p>
                  <div>
                    <span class="badge text-bg-light">NEXT</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="text_box1">
                  <h4>02</h4>
                  <hr />
                  <h4>
                    REFERRAL
                    <br />
                    QR CODE
                  </h4>
                  <div className="image-container">
                    <img src={img04} alt="" />
                  </div>
                  <p>Apply the code while ordering the combo product</p>
                  <div>
                    <span class="badge text-bg-light">NEXT</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="text_box1">
                  <h4>03</h4>
                  <hr />
                  <h4>
                    REFERRAL
                    <br />
                    REWARD
                  </h4>
                  <div className="image-container">
                    <img src={img05} alt="" />
                  </div>
                  <p>Once Someone places an order using your code</p>
                  <div>
                    <span class="badge text-bg-light">NEXT</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="text_box1">
                  <h4>04</h4>
                  <hr />
                  <h4>
                    REFERRAL
                    <br />
                    PROGRAM
                  </h4>
                  <div className="image-container">
                    <img src={img06} alt="" />
                  </div>
                  <p>you will get 10% of the order amount </p>
                  <div>
                    <span class="badge text-bg-light">NEXT</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ReferAndWallet;
