import React from 'react'
import "./CollarShirt.css"
const CollarShirt = () => {
  return (
    <div>
      <h4>CollarShirt</h4>
    </div>
  )
}

export default CollarShirt
