import React, { useState, useEffect, useRef } from 'react';
import Chatbot from '../Pages/charttt/Chatbot';
import './FinalChart.css';

const FinalChart = () => {
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const chatbotContainerRef = useRef(null);
  const chatbotButtonRef = useRef(null);

  const toggleChatbot = (event) => {
    event.stopPropagation();
    setIsChatbotOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        chatbotContainerRef.current &&
        !chatbotContainerRef.current.contains(event.target) &&
        !chatbotButtonRef.current.contains(event.target)
      ) {
        setIsChatbotOpen(false);
      }
    };

    // document.addEventListener('click', handleClickOutside);
    return () => {
    //   document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        id="chatbot-button"
        className="chatbot-button"
        onClick={toggleChatbot}
        ref={chatbotButtonRef}
      >
        <img
          src="https://cdn-icons-png.flaticon.com/512/4712/4712028.png"
          alt="Chatbot Icon"
        />
      </div>
      {isChatbotOpen && (
        <div
          id="chatbot-container"
          className="chatbot-container open"
          ref={chatbotContainerRef}
        >
          <Chatbot />
        </div>
      )}
    </div>
  );
};

export default FinalChart;
