// Cursor.js
import React, { useEffect, useState } from 'react';
import './Cursor.css';

import Tlog from "./Assets/tuniilogo.jpeg"




const Cursor = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (    
    <div
      className='custom-cursor'
      style={{
        left: `${cursorPosition.x}px`,
        top: `${cursorPosition.y}px`,
      }}
    >
      <img src={Tlog} alt="Tlog" className='img-fluid' />
    </div>

  );
};

export default Cursor;
