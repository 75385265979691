import React, { useState, useEffect } from "react";
import { firestore, auth } from "../../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Return = () => {
  const [userId, setUserId] = useState(null);
  const [orderNumber, setOrderNumber] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [orderProductsCombos, setOrderProductsCombos] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        fetchOrderItemsPlaced(user.uid);
        fetchOrderItemsCombosPlaced(user.uid);
      } else {
        setUserId(null);
        setOrderProducts([]);
        setOrderProductsCombos([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchOrderItemsPlaced = async (userId) => {
    try {
      const orderRef = collection(
        firestore,
        "AllOrderList",
        userId,
        "OrderItemPlaced"
      );
      const orderSnap = await getDocs(orderRef);
      const orderProducts = [];
      orderSnap.forEach((doc) => {
        orderProducts.push({ id: doc.id, data: doc.data() });
      });
      setOrderProducts(orderProducts);
    } catch (error) {
      console.error("Error fetching order products:", error);
    }
  };

  const fetchOrderItemsCombosPlaced = async (userId) => {
    try {
      const userDocItem = collection(
        firestore,
        "AllOrderList",
        userId,
        "OrderItemPlaced_Combo"
      );
      const userDocSnap = await getDocs(userDocItem);
      const orderProductsCombos = [];
      userDocSnap.forEach((doc) => {
        orderProductsCombos.push({ id: doc.id, data: doc.data() });
      });
      setOrderProductsCombos(orderProductsCombos);
    } catch (error) {
      console.error("Error fetching combo order products:", error);
    }
  };

  const handleFindOrder = async () => {
    // if (!orderNumber || !contactInfo) {
    //   toast.error("Please enter both Order Number and Contact Information");
    //   return;
    // }

    try {
      const filtered = [...orderProducts, ...orderProductsCombos].filter(
        (order) => order.data.orderID === orderNumber
      );
      setFilteredProducts(filtered);
      if (filtered.length === 0) {
        toast.error("No orders found matching the provided details.");
      }
    } catch (error) {
      console.error("Error finding order:", error);
      toast.error("Error finding order. Please try again.");
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="text-center">
              <h4 className="fw-bold text-primary">Returns & Exchanges</h4>
              <p className="text-muted">
                Enter your order number and Email or Phone to find your order
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4"></div>
          <div className="col-lg-4">
            <div className="my-2">
              <label htmlFor="orderNumber" className="form-label fw-bold">
                Order Id <span className="text-danger fs-4">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  name="orderNumber"
                  id="orderNumber"
                  className="form-control"
                  placeholder="Enter Order Number eg:N123456"
                  value={orderNumber}
                  onChange={(e) => setOrderNumber(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="my-2">
              <label htmlFor="contactInfo" className="form-label fw-bold">
                Phone Or Email <span className="text-danger fs-4">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  name="contactInfo"
                  id="contactInfo"
                  className="form-control"
                  placeholder="Enter Phone Or Email"
                  value={contactInfo}
                  onChange={(e) => setContactInfo(e.target.value)}
                />
              </div>
            </div> */}
            <div className="text-center">
              <button
                className="my-4 py-2 px-4 border-0 submitbtn_edit"
                onClick={handleFindOrder}
              >
                Find Your Order
              </button>
            </div>

            {filteredProducts.length > 0 && (
              <div className="row">
                <div className="col">
                  <h5 className="fw-bold">Order Details</h5>
                  {filteredProducts.map((order) => (
                    <div key={order.id} className="order-details">
                      <p>
                        <strong>Username:</strong>{" "}
                        {order.data.orderAddress.username}
                      </p>

                      <p>
                        <strong>Address:</strong>{" "}
                        {order.data.orderAddress.address_Line1},{" "}
                        {order.data.orderAddress.city},{" "}
                        {order.data.orderAddress.state},{" "}
                        {order.data.orderAddress.pincode}
                      </p>
                      <p>
                        <strong>Phone Number:</strong>{" "}
                        {order.data.orderAddress.phoneNumber}
                      </p>
                      <p>
                        <strong>Total Price:</strong> {order.data.totalPrice}
                      </p>
                      <h6>Products:</h6>
                      <ul>
                        {order.data.selectedItems.map((item) => (
                          <li key={item.id}>
                            <p>
                              <strong>Name:</strong> {item.name}
                            </p>
                            {/* <p><strong>Description:</strong> {item.description}</p> */}
                            <div className="d-flex">
                              <p>
                                <strong>Size:</strong> {item.size}
                              </p>

                              <img
                                src={item.imageturls}
                                alt={item.name}
                                className="img-fluid mx-3"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  marginTop: "-10px",
                                  borderRadius: "10px",
                                }}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <p className="text-muted">
              {" "}
              Click here to read our <a href="#">return/exchange policy</a>
            </p>
          </div>
          <div className="col-lg-4"></div>
        </div>
      </div>
    </>
  );
};

export default Return;
